/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:b3292642-2f0e-4691-b883-51ed319128ff",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_1GXMMjgMW",
    "aws_user_pools_web_client_id": "5mafm07sh2b4qucns2njl7fg4",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://3o3rwwevdje43eaqu6jotxazhm.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-v3rhugmt75gpviq7ztmzpuxdhy"
};


export default awsmobile;

import React, { Component } from 'react';
import logo from './logo.svg';
import fish from './images/Red-Herring.svg';
import './App.css';
import { withAuthenticator } from 'aws-amplify-react'
import Amplify, { Auth } from 'aws-amplify';
import aws_exports from './aws-exports';
Amplify.configure(aws_exports);

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={fish} className="App-logo" alt="logo" />
          <p>
            <code>Quibbling_as_a_Service</code>
          </p>
          <a
            className="App-link"
            href="https://en.wikipedia.org/wiki/Trivial_objections"
            target="_blank"
            rel="noopener noreferrer"
          >
            Embark on your QaaS journey!
          </a>
        </header>
      </div>
    );
  }
}

export default withAuthenticator(App, true);
